import type { FC } from "react"
import type { CoreLayoutProps } from "react-admin"
import { Admin, Layout, Resource, defaultTheme } from "react-admin"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"

import japaneseMessages from "@bicstone/ra-language-japanese"
import { purple, red } from "@mui/material/colors"
import * as Sentry from "@sentry/react"
import { Auth } from "aws-amplify"
import { domainMessages } from "common-utils"
import polyglotI18nProvider from "ra-i18n-polyglot"

import authProvider from "~/authProvider"
import awsConfig from "~/awsConfig"
import {
  CompanyCreate,
  CompanyEdit,
  CompanyList,
  FeatureTopicEdit,
  FeatureTopicList,
  InterviewTemplateList,
  JobEdit,
  JobList,
  JobSeekerList,
  JobSeekerShow,
  MailNotificationCreate,
  MailNotificationEdit,
  MailNotificationList,
} from "~/components/templates"
import { sentryDsn } from "~/config"
import dataProvider from "~/dataProvider"

import SideMenu from "./components/organisms/SideMenu"
import ApplicationLayout from "./components/templates/ApplicationLayout"
import ChatGptThreadList from "./components/templates/ChatGptThreadList"
import JobCharacteristicsList from "./components/templates/JobCharacteristicsList"
import JobSeekerPrivacySettingsList from "./components/templates/JobSeekerPrivacySettingsList"
import ApolloProvider from "./providers/ApolloProvider"

import type { TranslationMessages } from "ra-core"

Sentry.init({
  dsn: sentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  initialScope: {
    tags: { application: "admin" },
  },
  release: process.env.VITE_REVISION,
  environment: process.env.VITE_ENVIRONMENT,
})
Sentry.setTag("application", "admin-client")

Auth.configure(awsConfig)

const messages = {
  ...japaneseMessages,
  ...domainMessages,
}
const i18nProvider = polyglotI18nProvider(() => messages as TranslationMessages, "ja")

const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: red[500],
    },
  },
}

const CustomLayout = (props: CoreLayoutProps) => <Layout {...props} menu={SideMenu} />

const AdminRoutes = () => (
  <Admin
    title="Admin"
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    theme={theme}
    requireAuth
    layout={CustomLayout}
  >
    <Resource name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />
    <Resource name="jobs" list={JobList} edit={JobEdit} />
    <Resource name="jobSeekers" list={JobSeekerList} show={JobSeekerShow} />
    <Resource name="featureTopics" list={FeatureTopicList} edit={FeatureTopicEdit} />
    <Resource
      name="mailNotifications"
      list={MailNotificationList}
      edit={MailNotificationEdit}
      create={MailNotificationCreate}
    />
    <Resource name="jobCharacteristics" list={JobCharacteristicsList} />
    <Resource name="jobSeekerPrivacySettings" list={JobSeekerPrivacySettingsList} />
  </Admin>
)

const ApplicationRoute = () => (
  <ApolloProvider>
    <ApplicationLayout>
      <Outlet />
    </ApplicationLayout>
  </ApolloProvider>
)

const App: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<ApplicationRoute />}>
        <Route path="/interviewTemplates" element={<InterviewTemplateList />} />
        <Route path="/chatGptThreads" element={<ChatGptThreadList />} />
      </Route>
      <Route path="*" element={<AdminRoutes />} />
    </Routes>
  </BrowserRouter>
)

// biome-ignore lint/style/noDefaultExport: ...
export default App
