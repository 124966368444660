import type { UserIdentity } from "react-admin"

import * as Sentry from "@sentry/react"
import { Auth } from "aws-amplify"

import type { AmplifyUser } from "common-utils"

type loginParams = {
  username: string
  password: string
}

const authProvider = {
  login: async ({ username, password }: loginParams): Promise<void> => {
    try {
      const user = (await Auth.signIn(username, password)) as AmplifyUser
      Sentry.setUser({
        email: (user.username || username).toLowerCase(),
        id: user.userSub,
      })
    } catch (error) {
      Sentry.getCurrentScope().setUser(null)
      console.error("error signing in", error)
    }
  },
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  checkError: (error: any): Promise<void> => {
    console.error("dataProvider error:", error)

    return new Promise(error)
  },
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  checkAuth: async (_params: any): Promise<void> => {
    try {
      await Auth.currentAuthenticatedUser()
    } catch (error) {
      console.error("error signing in:", error)
      if (error === "The user is not authenticated") {
        Sentry.getCurrentScope().setUser(null)

        return Promise.reject(new Error(error))
      }
    }
  },
  logout: async (): Promise<void> => {
    try {
      await Auth.signOut()
      Sentry.getCurrentScope().setUser(null)
    } catch (error) {
      console.error("error signing out: ", error)
    }
  },
  getIdentity: (): Promise<UserIdentity> => Promise.resolve({ id: "id" }),
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  getPermissions: (params: any) => Promise.resolve(params),
}

// biome-ignore lint/style/noDefaultExport: ...
export default authProvider
