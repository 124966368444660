import type React from "react"
import { useRecordContext } from "react-admin"

import { GitHub, Twitter } from "@mui/icons-material"
import { Box, Link, Typography } from "@mui/material"
import { SocialAccountProvider } from "rikeimatch-graphql"

import noteLogo from "./images/note-logo.png"
import qiitaLogo from "./images/qiita-logo.png"

import type { ReactNode } from "react"
import type { JobSeeker, SocialAccount } from "rikeimatch-graphql"

const providers = [
  SocialAccountProvider.Github,
  SocialAccountProvider.Qiita,
  SocialAccountProvider.Twitter,
  SocialAccountProvider.Note,
]
const sortProviders = (a: SocialAccount, b: SocialAccount) =>
  providers.indexOf(a.provider) - providers.indexOf(b.provider)

const ProviderIcon: React.FC<{ account: SocialAccount }> = (props) => {
  switch (props.account.provider) {
    case SocialAccountProvider.Github:
      return <GitHub style={{ fontSize: 50, color: "#171515" }} />
    case SocialAccountProvider.Qiita:
      return <img src={qiitaLogo} alt="Qiita" height={50} />
    case SocialAccountProvider.Twitter:
      return <Twitter style={{ fontSize: 50, color: "#00acee" }} />
    case SocialAccountProvider.Note:
      return <img src={noteLogo} alt="note" height={50} />
    default:
      return <span />
  }
}

const JobSeekerSocialAccountField = ({
  source: _,
}: // label: __,
{
  source: keyof JobSeeker
  // label: string;
}): ReactNode => {
  const originalRecord = useRecordContext<JobSeeker>()
  const { socialAccounts } = originalRecord
  socialAccounts?.sort(sortProviders)

  if (!socialAccounts || socialAccounts.length === 0) {
    return <Typography variant="body2">未連携</Typography>
  }

  return (
    <Box display="flex" alignItems="flex-start">
      {socialAccounts.map((socialAcc) => (
        <Box key={socialAcc.profileUrl} p={1}>
          <Link href={socialAcc.profileUrl} target="_blank">
            <ProviderIcon account={socialAcc} />
          </Link>
        </Box>
      ))}
    </Box>
  )
}

JobSeekerSocialAccountField.defaultProps = {
  addLabel: true,
}

// biome-ignore lint/style/noDefaultExport: ...
export default JobSeekerSocialAccountField
