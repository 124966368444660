import type { CommonInputProps } from "react-admin"
import { InputHelperText, sanitizeInputRestProps } from "react-admin"

import TextField from "@mui/material/TextField"
import clsx from "clsx"
import PropTypes from "prop-types"
import { FieldTitle, useInput } from "ra-core"

import type { TextFieldProps } from "@mui/material/TextField"

// DateInputを魔改造したもの。
// 日付のうち、「日」を表示しないようにし、内部状態上、1日として保持する

/**
 * Form input to edit a Date string value in the "YYYY-MM-DD" format (e.g. '2021-06-23').
 *
 * Renders a date picker (the exact UI depends on the browser).
 *
 * @example
 * import { Edit, SimpleForm, MonthInput } from 'react-admin';
 *
 * const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <MonthInput source="published_at" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * @example
 * // If the initial value is a Date object, DateInput converts it to a string
 * // but you must pass a custom parse method to convert the form value
 * // (which is always a date string) back to a Date object.
 * <MonthInput source="published_at" parse={val => new Date(val)} />
 */
export const MonthInput = ({
  className,
  defaultValue,
  format = getStringFromDate,
  label,
  name,
  source,
  resource,
  helperText,
  margin,
  onBlur,
  onChange,
  parse = (value: string) => new Date(value),
  validate,
  variant,
  ...rest
}: DateInputProps) => {
  const { field, fieldState, formState, id, isRequired } = useInput({
    defaultValue,
    name,
    format,
    parse,
    onBlur,
    onChange,
    resource,
    source,
    validate,
    ...rest,
  })

  const { error, invalid, isTouched } = fieldState
  const { isSubmitted } = formState

  return (
    <TextField
      id={id}
      {...field}
      className={clsx("ra-input", `ra-input-${source}`, className)}
      type="month"
      size="small"
      variant={variant}
      margin={margin}
      error={(isTouched || isSubmitted) && invalid}
      helperText={
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      }
      label={
        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      InputLabelProps={defaultInputLabelProps}
      {...sanitizeInputRestProps(rest)}
    />
  )
}

MonthInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element]),
  resource: PropTypes.string,
  source: PropTypes.string,
}

export type DateInputProps = CommonInputProps & Omit<TextFieldProps, "helperText" | "label">

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || Number.isNaN(value.getDate())) {
    return ""
  }
  const pad = "00"
  const yyyy = value.getFullYear().toString()
  const MM = (value.getMonth() + 1).toString()

  return `${yyyy}-${(pad + MM).slice(-2)}`
}

const dateRegex = /^\d{4}-\d{2}$/
const defaultInputLabelProps = { shrink: true }

const getStringFromDate = (value: string | Date) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === "") {
    return ""
  }

  if (value instanceof Date) {
    return convertDateToString(value)
  }

  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value
  }

  return convertDateToString(new Date(value))
}
